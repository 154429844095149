<template>
  <div class="list">
    <div v-infinite-scroll="onReachBottom" infinite-scroll-disabled="loading" infinite-scroll-distance="10">
      <div class="listCt" v-for="(item, index) in list" :key="index">
        <div class="title">
          <img v-if="item.type == 1" src="@/assets/img/enterprise/property.png" />
          <img v-else-if="item.type == 2" src="@/assets/img/enterprise/water.png" />
          <img v-else-if="item.type == 3" src="@/assets/img/enterprise/electric.png" />
          <img v-else-if="item.type == 4" src="@/assets/img/enterprise/advertising.png" />
          <img v-else-if="item.type == 5" src="@/assets/img/enterprise/lease.png" />
          <img v-else-if="item.type == 6" src="@/assets/img/enterprise/the_rent.png" />
          <img v-else src="@/assets/img/enterprise/documents.png" />
          <span>{{ item.type_dictText }}账单</span>
        </div>
        <div class="project">
          <p class="project-name">机构名称</p>
          <p class="project-main">{{ item.orgId_dictText }}</p>
        </div>
        <div class="project">
          <p class="project-name">账单周期</p>
          <p class="project-main">{{ item.startTime }}~{{ item.endTime }}</p>
        </div>
        <div class="project">
          <p class="project-name">账单金额</p>
          <p class="project-main">￥{{ item.money }}</p>
        </div>
        <div class="project">
          <p class="project-name">创建日期</p>
          <p class="project-main">{{ item.createTime }}</p>
        </div>
         <div class="project">
          <p class="project-name">缴费状态</p>
          <p class="project-main">{{ item.payStatus_dictText }}</p>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="nodata">已无更多数据</div>
    <div v-if="!isEnd && loading" class="loading">
      <mt-spinner color="#178CF9" type="snake"></mt-spinner>
    </div>
  </div>
</template>

<script>
import { queryPageListOrgWx } from '@/api/enterprise'

export default {
  components: {},
  data() {
    return {
      loading: false,
      pageNo: 0,
      isEnd: false,
      list: []
    }
  },
  computed: {},
  mounted() {
    this.refresh()
  },
  methods: {
    // 背景图列表
    refresh() {
      this.isEnd = false
      this.list = []
      this.loadData(1)
    },
    async loadData(pageNo) {
      this.loading = true
      try {
        const { success, result } = await queryPageListOrgWx({
          pageSize: 10,
          pageNo
        })
        const totalPage = result.pages
        if (success) {
          if (+pageNo === 1) {
            this.list = result.records
          } else {
            this.list = this.list.concat(result.records)
          }
          this.pageNo = pageNo
          if (this.pageNo >= totalPage) {
            this.isEnd = true
          }
        }
      } catch (error) {
        this.isEnd = true
      } finally {
        this.loading = false
      }
    },
    onReachBottom() {
      if (this.isEnd) return
      this.loadData(this.pageNo + 1)
    },
    // 去往新增
    addNewRepair() {
      this.$router.push({
        name: 'repairAdd'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  padding: 10px 15px;
  background: #f7f8fc;
  font-weight: 'Source Han Sans CN';

  .listCt {
    background: #ffffff;
    padding: 0 15px;
    margin-bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 15px;
      border-bottom: solid 1px #e8e8e8;
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 27px;
        height: 27px;
        margin-right: 10px;
      }
    }

    .project {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-name {
        color: #666666;
      }

      &-main {
        color: #333333;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 40px;
    }
  }

  .load-all {
    /* 数据加载完毕提示信息 */
    padding-bottom: 15px;
    font-size: 14px;
    text-align: center;
    color: #c8c8cf;
  }
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nodata {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666666;
}
</style>
