var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.onReachBottom,
            expression: "onReachBottom"
          }
        ],
        attrs: {
          "infinite-scroll-disabled": "loading",
          "infinite-scroll-distance": "10"
        }
      },
      _vm._l(_vm.list, function(item, index) {
        return _c("div", { key: index, staticClass: "listCt" }, [
          _c("div", { staticClass: "title" }, [
            item.type == 1
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/enterprise/property.png")
                  }
                })
              : item.type == 2
              ? _c("img", {
                  attrs: { src: require("@/assets/img/enterprise/water.png") }
                })
              : item.type == 3
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/enterprise/electric.png")
                  }
                })
              : item.type == 4
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/enterprise/advertising.png")
                  }
                })
              : item.type == 5
              ? _c("img", {
                  attrs: { src: require("@/assets/img/enterprise/lease.png") }
                })
              : item.type == 6
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/enterprise/the_rent.png")
                  }
                })
              : _c("img", {
                  attrs: {
                    src: require("@/assets/img/enterprise/documents.png")
                  }
                }),
            _c("span", [_vm._v(_vm._s(item.type_dictText) + "账单")])
          ]),
          _c("div", { staticClass: "project" }, [
            _c("p", { staticClass: "project-name" }, [_vm._v("机构名称")]),
            _c("p", { staticClass: "project-main" }, [
              _vm._v(_vm._s(item.orgId_dictText))
            ])
          ]),
          _c("div", { staticClass: "project" }, [
            _c("p", { staticClass: "project-name" }, [_vm._v("账单周期")]),
            _c("p", { staticClass: "project-main" }, [
              _vm._v(_vm._s(item.startTime) + "~" + _vm._s(item.endTime))
            ])
          ]),
          _c("div", { staticClass: "project" }, [
            _c("p", { staticClass: "project-name" }, [_vm._v("账单金额")]),
            _c("p", { staticClass: "project-main" }, [
              _vm._v("￥" + _vm._s(item.money))
            ])
          ]),
          _c("div", { staticClass: "project" }, [
            _c("p", { staticClass: "project-name" }, [_vm._v("创建日期")]),
            _c("p", { staticClass: "project-main" }, [
              _vm._v(_vm._s(item.createTime))
            ])
          ]),
          _c("div", { staticClass: "project" }, [
            _c("p", { staticClass: "project-name" }, [_vm._v("缴费状态")]),
            _c("p", { staticClass: "project-main" }, [
              _vm._v(_vm._s(item.payStatus_dictText))
            ])
          ])
        ])
      }),
      0
    ),
    !_vm.loading
      ? _c("div", { staticClass: "nodata" }, [_vm._v("已无更多数据")])
      : _vm._e(),
    !_vm.isEnd && _vm.loading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("mt-spinner", { attrs: { color: "#178CF9", type: "snake" } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }